import logo from './logo.svg';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';

import Home from './screens/Home';
import Fomo from './screens/Fomo';
import Opia from './screens/Opia';
import Portal from './screens/Portal';
import PCS from './screens/PCS';


function App() {
  return (
    <div className="App">
    
    <Router>
      <Routes>
        <Route exact path = '/' element = {<Home/>}/>
        <Route exact path = '/pcs' element = {<PCS/>}/>
        <Route exact path = '/fomo' element = {<Fomo/>}/>
        <Route exact path = '/inventorymanagement' element = {<Portal/>}/>
        <Route exact path = '/opia' element = {<Opia/>}/>
      </Routes>
    </Router>


    </div>
  );
}

export default App;
