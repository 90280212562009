import React from 'react'
import {Link} from "react-router-dom"
import svete from '../assets/svete.mov'
// import flowers from '../assets/flowers.mov'

const Opia = () => {
  return (
    
    <div style={{padding:'20px'}}>
    <div><Link style={{textDecoration:'none', color:'inherit'}}to="/">
    &lt; back <br/> <br/></Link></div>
    <div>Opia Optics</div>
        <div><br/>
        Opia Optics is an digital art project I worked on in Fall of 2023. Looking for a creative outlet and interested in 
        learning more about computer graphics, I envisioned and developed these filters. With the help of my friend Sam Mutz, the
        image transformations were designed using Adobe design software and TouchDeisgner. In order to replicate the design with code
        I had to develop my own library of visual effects from scratch using glsl shaders. Shaders are lightweight programs designed to
        run on the GPU. Because they are designed to be lightweight I had to creatively employ math to manage the pixel calculations.
        I replicated over 30 effects including various blend modes, blurs, gradient mapping, brightness, hue, saturation and more.
        
        As I was looking for design inspiration in the world around me I was constantly thinking of how to take organic design principles and 
        replicate them with mathematics and code. While headache inducing, this experience served as a valauble emphasis of the fact that
        math is nature and that incredible effects can be produced by the layering and combination of the most fundamental operations.
        </div>

        <video controls width="300">
          <source src={svete} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <video controls width="300">
          <source src={flowers} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}

        <div><a style={{fontSize:'60px'}}href='https://svete-filter-5dcff18a7f42.herokuapp.com/'>try it out</a></div>

    
    
    </div>
  )
}

export default Opia