import React from 'react'
import {Link} from "react-router-dom"
const Portal = () => {
  return (
    <div style={{padding:'20px'}}>
    <div><Link style={{textDecoration:'none', color:'inherit'}}to="/">
    &lt; back <br/> <br/></Link></div>
    <div>Inventory Management System</div>
        <div><br/>
        I built an inventory management system for a luxury goods business. The portal faciliated business bewteen the business
        and consigners. The portal also gave analyzed transaction to provide profit metrics and possible optimizations.
        I implemented a database for inventory and user auth,
        market intelligent price analysis, ACH bank transfer payments, and all related UI.
        Includes an interface for users and an interface for management. Use of MERN Stack. 
        </div>


    
    
    </div>
  )
}

export default Portal