import React from 'react'
import {Link} from "react-router-dom"
import screen1 from '../assets/linkscreen1.png'
import screen2 from '../assets/linkscreen2.png'
import screen3 from '../assets/linkscreen3.png'
import screen4 from '../assets/linkscreen4.png'
import screen5 from '../assets/linkscreen5.png'

const Fomo = () => {
  return (
    <div style={{padding:'20px'}}>
    <div><Link style={{textDecoration:'none', color:'inherit'}}to="/">
    &lt; back <br/> <br/></Link></div>
    <div>FOMO</div>
        <div><br/>
        I built this app as a part of FOMO,a start-up founded and managed by Anderson MBA candidates. In addition to development,
        FOMO has been an opportunity to explore product management. As a part of the team I have helped with market research, user analysis,and launch strategy. 
        Built using React Native and Firebase, FOMO revolutionizes activity planning and discovery by connecting 
        social venues and users through their innovative UI. 
        Below are some screens from our app.
        </div>
        <img style={{width:'400px'}} src = {screen1}/>
        <img style={{width:'400px'}} src = {screen2}/>
        <img style={{width:'400px'}} src = {screen3}/>
        <img style={{width:'400px'}} src = {screen4}/>
        <img style={{width:'400px'}} src = {screen5}/>


    
    
    </div>
  )
}

export default Fomo