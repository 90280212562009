import React from 'react'
import {Link} from "react-router-dom"
import tool1 from '../assets/tool1.png'
import tool2 from '../assets/tool2.png'
import portal from '../assets/portal.png'
import home from '../assets/home.png'
import plantgpt from '../assets/plantGPT.png'
import grass from '../assets/grass.jpg'

import '../index.css'
const PCS = () => {

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    }

  return (
    <div style={{padding:'20px'}}>
    <div><Link style={{textDecoration:'none', color:'inherit'}}to="/">
    &lt; back <br/> <br/></Link></div>
    <h1>Plant Culture Systems</h1>
        <div><br/>
        Over the summer and fall of 2023 I worked for an Irvine-based started up called Plant Culture Systems. PCS develops vertical farming solutions.
        As the lead engineer for PCS I worked on various projects and developed them to fruition on my own.
        <br/><br/>

        <h3>Projects:</h3>
        <ul>
            <li onClick={() => scrollToSection('cnn')}>CNN for Lettuce Plant Growth Evaluation</li>
            <li onClick={() => scrollToSection('portal')}>Farm Portal</li>
            <li onClick={() => scrollToSection('plantgpt')}>PlantGPT</li>
            <li onClick={() => scrollToSection('vr')}>VR Farm Simulation</li>
        </ul>

        <h2 id="cnn" style={{paddingTop:'40px'}}>CNN for Lettuce Plant Growth Evaluation</h2>
        <div>
        The goal of this project was to train a convolutional neural network capable of accurately evaluating the weight of lettuce sprouts, providing a valuable metric on plant growth.
        <br/><br/>
        Although numerous papers have been published on training CNNs to estimate plant weight and overall growth, most that achieved high accuracy did so under idealized growing conditions. Such conditions render these models impractical for many industrial applications. Additionally, these studies often rely on a dense array of cameras and other sensors for each plot, which isn't economically viable at larger scales.
        <br/><br/>
        In many of these studies, data collection involved multiple rounds of cutting and weighing the plants. Given my constraints on time, manpower, and the availability of plants for early harvesting, I had to innovate in my data collection strategy to achieve a similar level of accuracy.
        <br/><br/><u>
        Easily replicable, robust and scalable, my solution resulted in an approach for data curation that can achieve high levels of accuracy from less than 10% of the data typically needed. In addition, this approach allows for quick and easy set up for new growing scenarios regardless of changes in the physical space.
        <br/><br/></u>
        
        To facilitate this, I devised a suite of user-friendly tools. These tools allow non-technical farmers to produce configuration files after setting up a new camera system. This configuration file is then used to reduce the view of entire plots from those cameras into a collection of normalized plant images. 
        <br/><br/>
        <img height = {300} src = {tool1}/>
        <img height = {300} src = {tool2}/>
        <br/><br/>
        The second part of my approach was an algorithm for extrapolating limited amounts of measured weight values to generate artificial yet accurate labels for the rest of the image data.
        <br/><br/>
        Using less than 400 measured data points I created a dataset of over 60,000 individually labeled images to train a resnet-18 model and achieved an accuracy of <u><b>84.7%</b></u> for the majority of the growth term. 
        <br/><br/>
        While the initial motivation of this project was to help automate transfers and determine ideal harvesting cycles, as I exceeded goals for accuracy I realized that if combined with the automation of environment control, the metrics provided by this model could be used in some regression task to optimize indoor growing conditions. This would lead to an increase in yield and a decrease in expensive operating costs including specifically expensive LED lighting. Given the limits of my employment this application has not been realized yet.

        </div>

 
        <h2 id="portal" style={{paddingTop:'40px'}}>Farm Portal and Sensor Modules</h2>
        <div>
        The Farm Portal is a platform for the management of vertical farm plots that I designed and implemented. For each plot the portal provides real time data about growing environments. Users can also view their plots in realtime and see estimations for plant weights determined by our trained CNN. The portal keeps track of growth cycles and generates growth forecasts based on outputs of the CNN.
        <br/><br/>
        <img height = {400} src = {portal}/>
        <img height = {400} src = {home}/>
        <br/><br/>
        I also designed sensor kits that detect the real time data displayed on the portal. The sensor modules are composed of Raspberry Pis and an array of sensors. Each sensor kit has an ID that can be linked to a plot when it is created on the portal.
        </div>

        <h2 id="plantgpt" style={{paddingTop:'40px'}}>PlantGPT</h2>
        <div>
        PlantGPT is a specialized farming and gardening chatbot that is trained on large amounts of application specific data. I tested the models against curated QA datasets and iteratively improved the internal prompts for optimal performance. I designed an associated UI and optimized knowledge base indexing for deployment.        <br/><br/>
        <br/><br/>
        <img height = {400} src = {plantgpt}/>
        <br/><br/>
        </div>

        <h2 id="vr" style={{paddingTop:'40px'}}>VR Farm Simulation</h2>
        <div>
        I designed a physical tool that would allow the company to easily make VR simulations of our farm. I used two fisheye cameras secured in an apparatus made of wood and a 3D printed structure to capture two video streams. With one video stream for each eye, they could be combined to create a 3D/stereo effect in the resultant video.        </div>
        <br/><br/>
        <img height = {400} src = {grass}/>
        <br/><br/>
        </div>
    </div>
  )
}

export default PCS