import React from 'react'
import icon from '../assets/Icontv.png'
import {Link} from "react-router-dom"
// import '../index.css'
const Home = () => {
  return (
    <div style={{
      display:'flex',
      flexDirection:'column',
      alignContent:'flex-start'
    }}>
      <div style={{
        display:'flex',
        justifyContent:'center'
      }}>
        <img alt = "icon" src = {icon}
        style={{
          width:'30%',
          maxWidth:'280px',
          paddingTop:'60px'
        }}/>
      </div>
      
      <div style={{
        display:'flex',
        justifyContent:'center'
      }}>
        <div>
          <div  style={{paddingBottom:'10px',paddingTop:'20px'}}>PROJECTS</div>
          <div><Link style={{textDecoration:'none', color:'inherit'}}to="/PCS">
          Plant Culture Systems</Link></div>
          <div><Link style={{textDecoration:'none', color:'inherit'}}to="/inventorymanagement">
          Inventory Management System</Link></div>
          <div><Link style={{textDecoration:'none', color:'inherit'}}to="/Fomo">
          FOMO Mobile Application</Link></div>
          <div><Link style={{textDecoration:'none', color:'inherit'}}to="/Opia">
          Opia Optics</Link></div>
        </div>
      </div>

      
    </div>
  )
}

export default Home